:root{
    --text-color: #000000;
    --button-boarder-color: rgba(255, 255, 255, 0.7);
    --button-hover-color: #AA9055;
    --button-text-color: #000000;
    --list-background-color: #ffffff;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    background-image: url(/static/media/MonthOfMiracles-V3.665128fc.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed; /* Keep the background fixed */
    min-height: 100vh; /* Ensure the body covers the entire viewport height */
    width: 100%;
    transition: background-image 0.5s ease;
}

.index {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.5s ease;
}

/* header menu section */

.index > .header-menu-wrapper {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-top: 5%;
    transition: all 0.5s ease;
}


/* ------- section for header ------- */

.index > .header-menu-wrapper > .header-section {
    width: 80%;
    position: relative;
}

.index > .header-menu-wrapper > .header-section > a > img {
    height: auto;
    width: 100%;
    transition: width 1s ease, height 1s ease;
}

/* ------- section for menu ------- */

.index > .header-menu-wrapper > .menu-section {
    position: relative;
    width: 40%;
}

.index > .header-menu-wrapper > .menu-section > .navigation > .topnav > li {
    list-style-type: none;
}

.index > .header-menu-wrapper > .menu-section > .navigation > .topnav > li > a {
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    font-weight: bold;
    border-style: solid;
    border-radius: 10px;
    border-width: 3px;
    text-align: center;
    padding: 8px 16px;
    text-decoration: none;
    float: right;
    font-size: 12px;
    color: #000000;
    color: var(--button-text-color);
    border-color: rgba(255, 255, 255, 0.7);
    border-color: var(--button-boarder-color);
}

.index > .header-menu-wrapper > .menu-section > .navigation > .topnav > li > a:hover {
    border-color: #AA9055;
    border-color: var(--button-hover-color);
}


/* ------- end of navigation section ------- */


/* ------- section for section ------- */
.index > .section-miracles {
    margin-top: 10px;
    width: 80%;
}

.index > .section-miracles > .miracles {
    margin-top: 75px;
    width: 80%;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    display: flex;
    flex-wrap: wrap-reverse;
    grid-gap: 20px;
    gap: 20px;
    transition: margin-top 1s ease, top 1s ease;
    padding-top: 25px;
    padding-bottom: 25px;
}

.card-default {
    background-image: url(/static/media/MonthOfMiracles-V3.665128fc.png);
}

.card-Praise {
    background-image: url(/static/media/MonthOfMiracles-Blue.b5ea604a.png);
}

.index > .section-miracles > .miracles > .card {
    margin: auto;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
    padding: 23px;
    width: 350px;
    height: 175px;
    overflow: hidden;
    position: relative;
    box-shadow:0 5px 8px 0 rgba(0,0,0,0.2),0 3px 10px 0 rgba(0,0,0,0.19)
}

.index > .section-miracles > .miracles > .card > p {
    position: absolute;
    top: 2%;
    left: 10%;
    font-family: "new-spirit", serif;
    font-weight: 400;
    font-style: normal;
}

.index > .section-miracles > .miracles > .card > .text-Praise {
   color: #ffffff;
}

.index > .section-miracles > .miracles > .card > .content {
    background-color: white;
    border-radius: 10px;
    padding: 15px;
    height: 100%;
    overflow-x: hidden; /* Hide horizontal overflow */
    overflow-y: auto; /* Allow vertical overflow */
    overflow-wrap: break-word;
    word-wrap: break-word;

}

.index > .section-miracles > .miracles > .card > .content > p {
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    font-weight: bold;
    color: #000000;
    color: var(--text-color);
    background-color: transparent;
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-size: 16px;
}

.index > .section-miracles > .miracles > .card  > .content > .text {
    line-height: 1.4;
}

.index > .section-miracles > .miracles > .card > .content > .emoji-container{
    position: absolute;
    bottom: 0;
    left: 80%;
    background-color: white;
    border-radius: 10px;
    border-width: 1px;
    border-color: black;
    border-style: solid;
    padding: 5px 7px;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    font-weight: bold;
    font-size: 12px;
}


.index > .section-miracles > .miracles > .card > .content > .emoji-container:hover{
    cursor: pointer;
    border-color: #AA9055;
    border-color: var(--button-hover-color);

}

.index > section > .location-prayer-container {
    display: flex;
    justify-content: space-between;
    width: 40%; /* Adjust the width as needed */
    padding: 10px;
    position: relative;
    grid-gap: 20px;
    gap: 20px;
    max-width: 360px;
    transition: width 1s ease;
}

.index > .location2-prayer-container {
    width: 80%; /* Adjust the width as needed */
    padding: 10px;
    position: relative;
    transition: width 1s ease;
}

.index > section > .location-prayer-container > .location-container,
.index > section > .location-prayer-container > .prayer-container {
    flex-grow: 1;
    flex-basis: calc(15% - 5px); /* Adjust the spacing between the containers */
    position: relative;
    margin: auto;
}

.index > section > .location-prayer-container > .location-container > select,
.index > section > .location-prayer-container > .prayer-container > select {
    border-radius: 10px;
    border-style: solid;
    color: #000000;
    color: var(--button-text-color);
    text-align: center;
    width: 100%;
    text-decoration: none;
    border-width: 3px;
    font-size: 12px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: bold;
    border-color: rgba(255, 255, 255, 0.7);
    border-color: var(--button-boarder-color);
    background-color: transparent;
}
.index > section > .location-prayer-container > .location-container > select:hover {
    border-color: #AA9055;
    border-color: var(--button-hover-color);
}


/* Additional styles for select (if needed) */
.index > section > .location-prayer-container > .location-container > select,
.index > section > .location-prayer-container > .prayer-container > select {
    padding: 8px; /* You can adjust padding as needed */
    float: right; /* You can add this property if it's required for select elements */
}

/* Style the select dropdown arrow */
.index > section > .location-prayer-container > .prayer-container > select::after,
.index > section > .location-prayer-container > .location-container > select::after {
    content: '\25BC'; /* Unicode for downward arrow */
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 12px;
    border-color: transparent;
}

.index > section > .location-prayer-container > .location-container > select > option,
.index > section > .location-prayer-container > .prayer-container > select > option {
    background-color: #ffffff;
    background-color: var(--list-background-color);
}

/* ------- section for menu ------- */

.index > .location2-prayer-container > .menu-section,
.index > section > .location-prayer-container > .menu-section {
    position: relative;
    flex-grow: 1;
    flex-basis: calc(15% - 5px);
}


.index > .location2-prayer-container > .menu-section > .navigation > .topnav > li,
.index > section > .location-prayer-container > .menu-section > .navigation > .topnav > li {
    list-style-type: none;
}

.index > .location2-prayer-container > .menu-section > .navigation > .topnav > li > a{
    position: relative;
    border-radius: 10px;
    border-style: solid;
    color: #000000;
    color: var(--button-text-color);
    text-align: center;
    padding: 9px;
    text-decoration: none;
    border-width: 3px;
    margin-left: 5%;
    border-color: rgba(255, 255, 255, 0.7);
    border-color: var(--button-boarder-color);
    font-size: 12px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: bold;
}

.index > section > .location-prayer-container > .menu-section > .navigation > .topnav > li > a {
    border-radius: 10px;
    border-style: solid;
    color: #000000;
    color: var(--button-text-color);
    text-align: center;
    padding: 9px;
    width: 100%;
    text-decoration: none;
    border-width: 3px;
    float: right;
    border-color: rgba(255, 255, 255, 0.7);
    border-color: var(--button-boarder-color);
    font-size: 12px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: bold;
}

.index > .location2-prayer-container > .menu-section > .navigation > .topnav > li > a:hover,
.index > section > .location-prayer-container > .menu-section > .navigation > .topnav > li > a:hover {
    border-color: #AA9055;
    border-color: var(--button-hover-color);
}

/* ------- end of navigation section ------- */



/* createmiracal page */

.index > .create-miracal {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center horizontally */
    position: relative;
}

.index > .create-miracal > .form-container {
    position: relative; /* Change to relative */
    margin-left: 15%;
    margin-right: 15%;
    width: 400px;
    text-align: left;
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 25px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: rgba(255, 255, 255, 0.50);
    transition: margin-top 0.3s, height 0.3s;
}

.index > .create-miracal > .form-container > .header {
    font-family: "new-spirit", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 32px;
    padding: 5px;
}

.index > .create-miracal > .form-container > .inputleft {
    padding: 5px;
    height: auto;
    resize: none;
    min-height: 100px;
    width: 100%;
    max-width: 100%;
    min-width: 200px;
    font-family: Helvetica Neue, b, sans-serif;
}

.index > .create-miracal > .form-container > .radio-container {
    display: flex;
    flex-direction: column;
}

.index > .create-miracal > .form-container > .radio-container > section {
    align-items: center;
    margin-bottom: 10px;
}

.index > .create-miracal > .form-container > .radio-container > section > .inputleftdod {
    margin-right: 8px;
}

.index > .create-miracal > .form-container > .radio-container > section > .label-input {
    font-family: Helvetica Neue, b, sans-serif;
    font-weight: bold;
    font-size: 16px;
}

.index > .create-miracal > .form-container > input {
    font-family: "new-spirit", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    position: relative;
    background-color: rgba(255, 255, 255, 0.49);
    border: none;
    border-radius: 15px;
    color: rgba(0, 0, 0, 100);
    padding: 10px;
    width: 100px;
    text-align: center;
    transition-duration: 0.4s;
    text-decoration: none;
    overflow: hidden;
    cursor: pointer;
}

.index > .create-miracal > .form-container > input:after {
    content: "";
    background: #fd6000;
    display: block;
    position: absolute;
    padding-top: 30%;
    padding-left: 350%;
    margin-left: -20px !important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s
}

.index > .create-miracal > .form-container > input:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 1s
}

.index > .create-miracal > .under-form {
    position: relative; /* Change to relative */
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 25px;
    width: 400px;
    top: 0;
    text-align: center;
    padding: 25px 25px 20px;
    background: rgba(255, 255, 255, 0.50);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.index > .create-miracal > .under-form > .first-text{
    font-family: "new-spirit", serif;
    font-weight: 600;
    font-style: normal;
}

.index > .create-miracal > .under-form > .second-text{
    font-family: "new-spirit", serif;
    font-weight: 400;
    font-style: normal;
}



.index > .create-miracal > .under-form > p {
    font-family: "new spirit", Serif, sans-serif;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
}

/* end of createmiracal page */

/* begin checkmiracal page */

.index > section > .location {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    display: flex;
    flex-wrap: wrap-reverse;
    grid-gap: 20px;
    gap: 20px;
    padding: 10px;
    width: 90%;
    margin-top: -30px;
}

.index > section > .check-location {
    position: relative;
    display: flex;
    flex-wrap: wrap-reverse;
    grid-gap: 20px;
    gap: 20px;
    padding: 10px;
    width: 90%;
}

.index > .section-miracles > .miracles-check {
    position: relative;
    grid-gap: 20px;
    gap: 20px;
    padding: 10px;
    width: 90%;
    top: 25%;
    overflow: auto;
    height: 700px;
    background-color: white;
    border-radius: 10px;
}

.index > .section-miracles > .miracles-check > h2 {
    height:auto ;
}

.index > .section-miracles > .miracles-check > ul{
    list-style-type: none;
    padding: 0;
    border: 1px solid #ddd;
    position: relative;
}

.index > .section-miracles > .miracles-check > ul > li {
    padding: 8px 16px;
    border-bottom: 1px solid #ddd;
    color: #000000;
    color: var(--text-color);
}
.index > .section-miracles > .miracles-check > ul > li:last-child {
    border-bottom: none;
}

.index > .section-miracles > .miracles-check > .details {
    position: absolute;
    top: 0; /* Adjust the top position to overlap the list */
    background-color: white; /* Optional: Add a background color for better visibility */
    padding: 10px;
    border: 1px solid #ddd;
    right: 30px;
}

.button-container {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    margin-top: 10px; /* Adjust as needed */
}

.button-container button {
    margin-top: 5px; /* Adjust the spacing between buttons */
}

/* ------- end of section section ------- */

input:invalid {
    border: red solid 3px;
}

/* ------- end of main section ------- */

@media(max-width: 456px) {
    .index > section > .location-prayer-container > .location-container > select,
    .index > section > .location-prayer-container > .prayer-container > select {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

@media (min-width: 999px) and (min-height: 999px) {
    .index > .header-menu-wrapper {
        margin-top:50px ;
    }

    .index > .header-menu-wrapper > .header-section > a > img {
        height: 202px;
        width: 360px;
    }

    .index > .location2-prayer-container,
    .index > section > .location-prayer-container {
        padding-top: 10px;
    }

}

@media (min-width: 999px), (min-height: 999px) {
    .index > .header-menu-wrapper {
        margin-top:50px ;
    }

    .index > .header-menu-wrapper > .header-section > a > img {
        height: 202px;
        width: 360px;
    }
    .index > .create-miracal > .form-container{
        width: 80%;
    }
    .index > .create-miracal > .under-form{
        width: 80%;
    }
}
@media (min-width: 999px), (min-height: 100px) {
    .index > .header-menu-wrapper > .header-section > a > img{
        height: 202px;
        width: 360px;
    }

    .index > .header-menu-wrapper {
        margin-top:50px ;
    }
    .index > .create-miracal > .form-container{
        width: 80%;
    }
    .index > .create-miracal > .under-form{
        width: 80%;
    }

    .index > .location2-prayer-container,
    .index > section > .location-prayer-container{
        width: 90%;
    }
}

@media (min-height: 999px) {
    .index > .header-menu-wrapper {
        margin-top: 50px;
    }
}


@media (min-width: 2201px) {
    .index > .section-miracles > .miracles  {
        margin-top: -200px;
        display: flex;
        flex-wrap: wrap-reverse;
        width: 60%;
        left: 55%;
    }
}

@media (max-width: 500px) {
    .index > .header-menu-wrapper > .header-section > a > img {
        height: auto;
        width: 100%;
    }

    .index > .location2-prayer-container,
    .index > section > .location-prayer-container{
        margin: auto;
    }

    .index > .header-menu-wrapper{
        justify-content: space-evenly;
        width: 80%;

    }

}


